import React from 'react';
import { graphql } from 'gatsby';
import LegalPage from 'components/pages/LegalPage';

const seo = {
  title: 'Q°emotion - Politique de confidentialité',
  keywords: [],
  description:
    'Q°emotion est capable de transformer vos verbatims clients ou employés en insights pour réduire votre taux d’attrition. Prenez connaissance de notre politique de confidentialité.',
};

export default props => <LegalPage {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    dataBlockText: markdownRemark(
      frontmatter: { pageType: { eq: "privacyPolicy" }, lang: { eq: "fr" } }
    ) {
      rawMarkdownBody
    }
  }
`;
